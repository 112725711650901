import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";

export const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div>
      <div className="px-4 py-5 mx-auto md:px-24 lg:px-8 bg-navy">
        <div className="relative flex items-center justify-between">
          <Link
            to="/"
            aria-label="Company"
            title="The Kenzie Group"
            className="inline-flex items-center text-gold"
          >
            <img src={logo} alt="Company Logo" className="h-20 w-20 mr-2" />
            <span className="ml-2 text-xl font-bold tracking-wide text-gold uppercase">
              The Kenzie Group
            </span>
          </Link>
          <div className="p-6 text-gold border border-gold">
            <p>We Speak Romanian!</p>
            <p>Vorbim Românește!</p>
          </div>
          {/* Hide on small screens, show on large screens */}
          <ul className="hidden lg:flex items-center space-x-8">
            <li>
              <Link
                to="/about-us"
                aria-label="About Us"
                title="About Us"
                className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/private-investigation-services"
                aria-label="Services"
                title="Services"
                className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/client-testimonials"
                aria-label="Testimonials"
                title="Testimonials"
                className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                aria-label="Frequently Asked Questions"
                title="FAQ"
                className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                aria-label="Contact Us"
                title="Contact Us"
                className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-s font-bold uppercase duration-1000 transition-all text-gold hover:bg-gold/70 active:bg-dark-gold/40 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg
                class="w-5 text-gray-600"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
          <div className="absolute top-0 left-0 w-full z-10 lg:hidden">
            <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <Link
                        to="/"
                        aria-label="The Kenzie Group"
                        title="The Kenzie Group"
                        class="inline-flex items-center"
                      >
                        <img
                          src={logo}
                          alt="Company Logo"
                          class="w-8"
                          viewBox="0 0 24 24"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          stroke="currentColor"
                          fill="none"
                        />
                        <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                          The Kenzie Group
                        </span>
                      </Link>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg
                          class="w-5 text-gray-600"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <Link
                          href="/"
                          aria-label="Home"
                          title="Home"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/private-investigation-services"
                          aria-label="Services"
                          title="Services"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Services
                        </a>
                      </li>
                      <li>
                        <a
                          href="/client-testimonials"
                          aria-label="Testimonials"
                          title="Testimonials"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Testimonials
                        </a>
                      </li>
                      <li>
                        <a
                          href="/faq"
                          aria-label="Frequently Asked Questions"
                          title="FAQ"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a
                          href="/contact-us"
                          aria-label="Contact Us"
                          title="Contact Us"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <svg
        className="h-6 w-full text-gold"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentcolor" // Replace with your chosen color in hex format
          d="M1440 32L1320 37.3C1200 43 960 53 720 53.3C480 53 240 43 120 37.3L0 32V0H120C240 0 480 0 720 0C960 0 1200 0 1320 0H1440V32Z"
        />
      </svg>
    </div>
  );
};

export default Nav;
