// pages/HomePage.js

import React, { useState } from 'react';
import BasicCard from '../components/common/BasicCard';
import mtjeff from '../assets/mt-jefferson.jpg';
import CollapsibleSidebar from '../components/common/CollapsibleSidebar';

const aboutData = [
  {
    id: 1,
    category: "Our Team",
    content: "Our team comprises seasoned professionals with extensive backgrounds in law enforcement and legal research, ensuring we bring a broad spectrum of expertise to every investigation."
  },
  {
    id: 2,
    category: "Our Process",
    content: "We follow a rigorous process that begins with a thorough consultation to understand your unique needs, followed by a strategic investigation plan tailored to deliver results efficiently and discreetly."
  },
  {
    id: 3,
    category: "FAQ",
    content: "We've compiled answers to frequently asked questions to help you understand the scope of our services, our methods, and how we can assist you in achieving your objectives.",
    link: "/faq"
  },
  {
    id: 4,
    category: "Hiring a private investigator: What to Know",
    content: "Hiring a private investigator is a significant decision. We provide clear guidance on what to expect, how to prepare, and the legal considerations involved in engaging a private investigator."
  },
  {
    id: 5,
    category: "Privacy and Security",
    content: "Your privacy and security are paramount. We adhere to strict confidentiality agreements and employ advanced security measures to protect the information and trust you place in us."
  },
  {
    id: 6,
    category: "Legal Rights and Advice",
    content: "Understanding your legal rights is crucial. We provide expert advice to navigate the complexities of the law, ensuring your investigation is conducted within legal boundaries."
  },
  // {
  //   id: 7,
  //   category: "Case Studies",
  //   content: "Explore our archive of case studies where our investigative efforts have made a significant difference, showcasing our methods and the results we've achieved for our clients."
  // },
  {
    id: 8,
    category: "Testimonials",
    content: "Hear directly from our clients about their experiences working with us and the impact our services have had on their personal and professional lives.",
    link: "/testimonials"
  }
];


const AboutPage = () => {
  const [selectedAbout, setSelectedAbout] = useState(aboutData[0])

  return (
    <div className="flex h-screen bg-off-white overflow-hidden w-full"
    style={{
      backgroundImage: `url(${mtjeff})`,
      backgroundSize: 'cover', // Ensure the background covers the section
      backgroundPosition: 'center', // Center the background image
      width: '100%', // Ensure the div takes up the intended width
      height: 'auto', // Adjust based on content or set specific height
    }}
      >
      <CollapsibleSidebar
            items={aboutData}
            selectedItem={setSelectedAbout}
            setSelectedItem={setSelectedAbout}
            />
      <div className="flex-grid">
        <h1 className='text-center text-4xl lg:text-5xl font-bold text-navy p-6 m-6 bg-opacity-50 rounded-lg'>Comprehensive Private Detective Solutions</h1>
      {/* About Detail View */}
      <BasicCard
        key={selectedAbout.id}
        className="fade-in bg-forest-green p-6 rounded-lg shadow-lg opacity-80"
        category={selectedAbout.category}
        content={selectedAbout.content}
        link={selectedAbout.link}
        />
        </div>
    </div>
  );
};

export default AboutPage;


