import React, { useState } from 'react';

const CollapsibleSidebar = ({ items, selectedItem, setSelectedItem }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (window.innerWidth <= 768) { // or another breakpoint
      setIsCollapsed(true);
    }
  };

  return (
    <div className={`relative ${isCollapsed ? 'w-16' : 'w-1/3'} transition-width duration-300`}>
      <button onClick={toggleSidebar} className="absolute top-0 right-0 p-2 z-10">
        {isCollapsed ? (
          // Right Chevron
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        ) : (
          // Left Chevron
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        )}
      </button>
      <div className={`${isCollapsed ? 'hidden' : 'block'} bg-forest-green shadow-md p-6 rounded-lg overflow-y-auto h-full`}>
        <ul className="divide-y divide-gray-200">
          {items.map((item) => (
            <li
              key={item.id}
              className={`cursor-pointer p-5 text-center transition duration-300 ease-in-out transform hover:scale-105 hover:text-gold hover:bg-gold/70 hover:shadow-lg text-dark-gold ${selectedItem.id === item.id ? 'bg-gold text-charcoal' : ''}`}
              onClick={() => handleItemClick(item)}
            >
              {item.category}
              </li>
        ))}
      </ul>
    </div>
  </div>
);
           
  
};

export default CollapsibleSidebar;
