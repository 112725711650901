// pages/HomePage.js

import React from 'react';
import HeroSection from '../components/layout/HeroSection';
import BasicCard from '../components/common/BasicCard';
import ndia from "../assets/ndia-logo-small.png";
import oali from "../assets/oali-logo.jpg";
import ocdla from "../assets/ocdla-logo.png"

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <div className="flex flex-row flex-wrap justify-center items-stretch space-x-4">
        <div className="flex flex-1 min-w-0 max-w-[21%]">
          <BasicCard
            category="credentials"
            title="OALI"
            content="The Oregon Association of Licensed Investigators, Inc was founded in 1984 and is the oldest and largest organization of licensed private investigators in the state of Oregon."
            imageUrl={oali}
            link="https://oali.org/"
            />
          </div>
          <div className="flex flex-1 min-w-0 max-w-[21%]">
            <BasicCard
              category="credentials"
              title="NDIA"
              content="The National Defender Investigator Association (NDIA) is the only international organization to represent a constituency dedicated solely to the investigative arm of indigent defense"
              imageUrl={ndia}
              link="https://www.ndia.net/"
              />
          </div>
          <div className="flex flex-1 min-w-0 max-w-[21%]">
            <BasicCard
              category="credentials"
              title="OCDLA"
              content="The mission of the Oregon Criminal Defense Lawyers Association’s Public Defense Reform Task Force (“Task Force”) is to bring together diverse voices from within OCDLA to advocate for a fully funded public defense system that maximizes the quality of services our members provide."
              imageUrl={ocdla}
              link="https://www.ocdla.org/"
              />
          </div>
        </div>
      </div>
    );
  };

  export default HomePage;
