import React, { useState } from 'react';
import BasicCard from '../common/BasicCard';
import testimonialsData from '../../data/testimonials';

const TestimonialsCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === testimonialsData.length - 1;

  const goToNext = () => {
    if (!isNextDisabled) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const goToPrev = () => {
    if (!isPrevDisabled) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  const activeTestimonial = testimonialsData[activeIndex];

  return (
    <div className="carousel-container flex flex-col items-center justify-center relative">
      {/* Navigation Controls */}
      <div className="carousel-controls flex justify-center items-center mb-2">
        <button 
          onClick={goToPrev}
          disabled={isPrevDisabled}
          className={`prev-button mr-2 bg-dark-gold hover:bg-gold text-off-white font-bold py-2 px-4 rounded ${isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
        {isPrevDisabled ? "First" : "Prev"} {/* Change text based on condition */}
        </button>
        
        {/* Dots indicators with improved accessibility */}
        <div className="dots flex justify-center items-center mt-4">
  {testimonialsData.map((_, index) => (
    <button
      key={index}
      aria-label={`Go to testimonial ${index + 1}`}
      className={`dot h-4 w-4 mx-1 m-4 rounded-full ${activeIndex === index ? 'bg-light-navy' : 'bg-navy'} focus:outline-none focus:ring-2 focus:ring-gold`}
      style={{ border: '1px solid white' }} // Added for visibility, adjust as needed
      onClick={() => setActiveIndex(index)}
    ></button>
  ))}
</div>


        <button 
          onClick={goToNext}
          disabled={isNextDisabled}
          className={`next-button ml-2 bg-dark-gold hover:bg-gold text-off-white font-bold py-2 px-4 rounded ${isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isNextDisabled ? "Last" : "Next"}
        </button>
      </div>

      <BasicCard
        category={activeTestimonial.descriptor}
        title={activeTestimonial.author}
        content={activeTestimonial.quote}
        imageUrl={activeTestimonial.image}
        link={activeTestimonial.link}
      />
    </div>
  );
}

export default TestimonialsCarousel;
