const BasicCard = ({ category, title, content, imageUrl, link }) => {
  return (
    <div className="flex p-4 m-2 items-center justify-center">
      <div className="relative flex flex-col w-full rounded-xl bg-forest-green border border-navy shadow-md h-full">
        <div className="flex flex-col md:flex-row items-center p-2">
          {imageUrl && (
            <div className="w-full md:w-auto shrink-0 h-[100px] bg-white">
              <img src={imageUrl} alt="" className="h-full w-full object-cover rounded-xl" />
            </div>
          )}
          <div className="md:pl-4 text-center md:text-left flex flex-col">
            {category && <h6 className="text-sm font-bold text-navy p-1">{category}</h6>}
            <h4 className="text-lg font-bold text-dark-gold p-1">{title}</h4>
          </div>
        </div>
        <div className="p-4 flex-1">
          <p className="text-sm text-off-white">{content}</p>
        </div>
        <div className="p-2 text-center">
          {link && (
            <a href={link} className="inline-block">
              <button className="bg-gold text-white font-bold uppercase px-6 py-2 rounded hover:bg-dark-gold">
                Read More
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};



export default BasicCard;
