// pages/ContactPage.js

import React from 'react';
import ContactForm from '../components/layout/ContactForm';
const ContactPage = () => {
  return (
    <div>
        <ContactForm />
    </div>
  );
};

export default ContactPage;
