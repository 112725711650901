import Button from "../common/BasicButton";
import mtjeff from '../../assets/jefferson-sunset-lake.jpg'; // Ensure this path is correct

export const HeroSection = () => {
  const heroStyles = {
    background: {
      backgroundImage: `url(${mtjeff})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    button: `bg-gold hover:bg-dark-gold text-charcoal font-bold py-2 px-4 rounded border-b-4 border-burgundy hover:border-burgundy-light`,
    accentText: `text-off-white`,
  };

  return (
    <div style={heroStyles.background} className="h-screen flex flex-col justify-center items-center text-center">
      <h1 className="text-6xl font-bold text-off-white mb-4">
        Integrity, Diligence, and Confidentiality
      </h1>
      <Button 
        label="Contact Us"
        href="/contact"
      />
    </div>
  );
}

export default HeroSection;

