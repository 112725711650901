// pages/HomePage.js

import React from 'react';
import TestimonialsCarousel from '../components/common/TestimonialCarousel';
import portlandbg from '../assets/cityscape-and-skyline-of-portland-oregon_800.jpg'
const TestimonialsPage = () => {
  return (
    <div
    style={{
      backgroundImage: `url(${portlandbg})`,
      backgroundSize: 'cover', // Ensure the background covers the section
      backgroundPosition: 'center', // Center the background image
      width: '100%', // Ensure the div takes up the intended width
      height: 'auto', // Adjust based on content or set specific height
    }}>
      <TestimonialsCarousel />
    </div>
  );
};

export default TestimonialsPage;
