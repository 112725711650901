import React from 'react';

const PrivacyPolicy = () => (
  <div className="max-w-4xl mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold mb-4">Privacy Policy for The Kenzie Group</h1>
    <p className="mb-2">Effective Date: February 21, 2024</p>
    <p className="mb-4">The Kenzie Group respects the privacy of our users and is committed to protecting it through our compliance with this policy. This Privacy Policy describes the personal information that we collect from you, or that you provide on our website, and how that information may be used or disclosed by us.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
    <h3 className="text-xl font-semibold mt-4 mb-2">Personal Information</h3>
    <p className="mb-4">When you fill out the contact form on our website, we may collect personal information such as:</p>
    <ul className="list-disc list-inside mb-4">
      <li>Your name</li>
      <li>Email address</li>
      <li>Phone number</li>
      <li>Any other information you choose to provide in the message section of the form</li>
    </ul>
    <p>This information is used to respond to your inquiries, to provide you with the services you request, and to offer you additional information about our services, should you agree to receive such information.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">2. Use of Your Information</h2>
    <p className="mb-4">We use information that we collect about you or that you provide to us, including any personal information:</p>
    <ul className="list-disc list-inside mb-4">
      <li>To present our website and its contents in a suitable and effective manner for you and for your computer.</li>
      <li>To provide you with information, products, or services that you request from us.</li>
      <li>To fulfill any other purpose for which you provide it.</li>
      <li>To notify you about changes to our website or any products or services we offer or provide through it.</li>
      <li>To allow you to participate in interactive features on our website when you choose to do so.</li>
      <li>In any other way we may describe when you provide the information.</li>
      <li>For any other purpose with your consent.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-6 mb-2">3. Sharing Your Information</h2>
    <p className="mb-4">We do not sell, rent, or share personal information with third parties except:</p>
    <ul className="list-disc list-inside mb-4">
      <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
      <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
      <li>To enforce or apply our terms of use and other agreements, including for billing and collection purposes.</li>
      <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of The Kenzie Group, our customers, or others.</li>
    </ul>

    <h2 className="text-2xl font-semibold mt-6 mb-2">4. Data Security</h2>
    <p className="mb-4">We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">5. Changes to Our Privacy Policy</h2>
    <p className="mb-4">It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting our website and this privacy policy to check for any changes.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">6. Contact Information</h2>
    <p className="mb-4">To ask questions or comment about this privacy policy and our privacy practices, contact us at: info@thekenziegroup.com.</p>
  </div>
);

export default PrivacyPolicy;

