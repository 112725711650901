import React from 'react';

const TermsOfService = () => (
  <div className="max-w-4xl mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold mb-4">Terms of Service for The Kenzie Group</h1>
    <p className="mb-2">Last Updated: February 21st, 2024</p>
    <p className="mb-4">Welcome to The Kenzie Group's website. By accessing our website or using our services, you agree to be bound by the following terms and conditions (the "Terms of Service"). If you do not agree to all these terms, you may not access the website or use the services.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">1. Acceptance of Terms</h2>
    <p className="mb-4">This agreement is between The Kenzie Group ("us", "we", or "our") and you, the user of our website and services ("you" or "your"). By using our website, you agree to these Terms of Service and any additional terms applicable to certain programs in which you may elect to participate.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">2. Privacy Policy</h2>
    <p className="mb-4">Please refer to our Privacy Policy for information on how we collect, use, and disclose information from our users.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">3. Use of Our Service</h2>
    <h3 className="text-xl font-semibold mt-4 mb-2">Website Use</h3>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Permitted Use:</strong> The website is provided for your personal and non-commercial use, to obtain information about our services.</li>
      <li><strong>Prohibited Use:</strong> You agree not to use the website for any unlawful purpose or in any way that interrupts, damages, or impairs the service.</li>
    </ul>
    <p className="mb-4">When you decide to hire a private investigator through The Kenzie Group, you acknowledge that such engagement will require you to enter into a separate agreement that will govern the provision of investigation services. This agreement will detail specific terms related to the services, confidentiality obligations, payment terms, and any limitations of liability or disclaimers specific to the investigative services provided. It is important to read and understand the terms of that separate agreement, as those terms will directly affect your rights and obligations.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">4. Intellectual Property Rights</h2>
    <p className="mb-4">All content on this website, including text, graphics, logos, and images, is the property of The Kenzie Group or its content suppliers and protected by international copyright laws.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">5. Disclaimers</h2>
    <p className="mb-4">The website and our services are provided on an "as is" and "as available" basis. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">6. Limitation of Liability</h2>
    <p className="mb-4">The Kenzie Group will not be liable for any damages of any kind arising from the use of our website or from any information, content, materials, or products included on or otherwise made available to you through the website, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">7. Amendments to These Terms</h2>
    <p className="mb-4">We reserve the right to make changes to these Terms of Service at any time. Your continued use of the website and our services following the posting of changes will mean you accept those changes.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">8. Governing Law</h2>
    <p className="mb-4">These Terms of Service and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of Oregon and Connecticut (where applicable).</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">9. Contact Us</h2>
    <p className="mb-4">For any questions about these Terms of Service, please contact us at info@thekenziegroup.com.</p>
  </div>
);

export default TermsOfService;

