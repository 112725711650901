import React, { useState } from 'react';
import BasicCard from '../components/common/BasicCard';
import bridge from '../assets/bridge-of-the-gods.jpg';
import CollapsibleSidebar from '../components/common/CollapsibleSidebar';

const faqData = [
    {
      id: 1,
      category: "What can a private investigator legally do?",
      content: "Private investigators can legally perform a wide range of tasks, including background checks, surveillance, finding missing persons, and gathering information for legal, personal, or business purposes. They can access public records, conduct interviews, and use technology to track down information. However, they must operate within the law; this means they cannot trespass on private property without consent, impersonate law enforcement officials, wiretap phones without permission, or obtain information through illegal means. The specific legal boundaries vary by jurisdiction, so what a private investigator can do legally might differ from one place to another."
    },
    {
      id: 2,
      category: "How much does it cost to hire a private investigator?",
      content: "The pricing for hiring a private investigator can fluctuate significantly based on factors such as the intricacy of the case, the investigator's level of expertise, and the geographic area. Fees are typically hourly, with rates varying widely. Specialized services like surveillance or technical investigations tend to incur higher costs. Some investigators may opt for a flat fee structure, particularly for services like background checks. Furthermore, additional expenses related to the investigation, such as travel or specialized equipment, may be included in the total cost of the investigation. It's advisable to have a comprehensive discussion regarding all fees and potential expenses beforehand to prevent unexpected charges."
    },
    {
      id: 3,
      category: "Is hiring a private investigator confidential?",
      content: " Hiring a private investigator is a lot like entrusting someone with sensitive personal info; you expect them to handle it with care and not share it with others. Private investigators are legally and ethically bound to keep your details and the nature of their investigation private. This means they can’t just talk about your case with anyone who’s curious. There are laws in place that make sure they keep things under wraps, except in rare situations where they might be required by a court to share some information.\n\nBefore you hire one, it’s smart to chat about how they guarantee confidentiality. It’s important to choose a private investigator who has a good track record and follows the rules of their profession to ensure your information stays safe and secret."
    },
    {
      id: 4,
      category: "What types of cases do private investigators handle?",
      content: "Private investigators handle a diverse array of cases, ranging from personal matters to corporate investigations. Personal cases often include surveillance for suspected infidelity, locating missing persons, or conducting background checks. For businesses, they might investigate employee theft, fraud, compliance issues, or conduct due diligence. Legal professionals also hire PIs for gathering evidence in civil or criminal cases, including serving legal documents or locating witnesses. Each type of case requires specific skills and knowledge, so investigators often specialize in certain areas to provide the most effective service."
    },
    {
      id: 5,
      category: "How do I know if a private investigator is qualified or reputable?",
      content: "To determine if a private investigator is qualified and reputable, start by checking their licensing status with the relevant state or country licensing board, as most places require PIs to be licensed. Look for reviews or testimonials from previous clients to gauge their reliability and quality of work. Additionally, consider their professional experience and areas of specialization to ensure they match your needs. Joining professional associations and having certifications can also be a sign of a committed and knowledgeable private investigator. Lastly, a consultation meeting can help you assess their professionalism and whether they communicate clearly and confidentially."
    },
    {
      id: 6,
      category: "What kind of information do I need to hire a private investigator?",
      content: "To hire a private investigator, you'll need to provide detailed information about the person or situation you're investigating, including any relevant documents, photographs, and your objectives for the investigation. Clarifying what you hope to achieve helps the private investigator tailor their approach to your needs."
    },
    {
      id: 7,
      category: "How long does an investigation take?",
      content: "The duration of an investigation can vary widely, from a few hours or days for straightforward cases to several weeks or even months for more complex situations. Factors influencing the timeline include the investigation's scope, the difficulty of obtaining information, among other things."
    },
    {
      id: 8,
      category: "What is the difference between hiring a private investigator and doing the investigation myself?",
      content: "Hiring a private investigator offers expertise, legal knowledge, and access to resources and techniques not typically available to the public. While conducting your own investigation might seem cost-effective, a private investigator can navigate legal restrictions and ethical considerations more safely and efficiently, potentially saving time and ensuring the investigation's legality."
    },
    {
      id: 9,
      category: "What are the outcomes or deliverables of an investigation?",
      content: "The outcomes or deliverables of an investigation typically include a detailed report of findings, possibly supplemented with photographic, video, or audio evidence. In legal cases, a private investigator might also provide testimony in court. The exact deliverables will depend on the investigation's goals and the type of information gathered."
    }
  ];
  

  const FaqPage = () => {
    const [selectedFaq, setSelectedFaq] = useState(faqData[0]);

    return (
        <div className="flex h-screen bg-off-white overflow-hidden w-full"
        style={{
          backgroundImage: `url(${bridge})`,
          backgroundSize: 'cover', // Ensure the background covers the section
          backgroundPosition: 'center', // Center the background image
          width: '100%', // Ensure the div takes up the intended width
          height: 'auto', // Adjust based on content or set specific height
        }}>
          <CollapsibleSidebar
            items={faqData}
            selectedItem={selectedFaq}
            setSelectedItem={setSelectedFaq}
          />
            {/* Service Detail View */}
            <div className='opacity-80'>
              <BasicCard
                  key={selectedFaq.id}
                  className="fade-in bg-forest-green p-6 rounded-lg shadow-lg"
                  category={selectedFaq.category}
                  content={selectedFaq.content}
              />
            </div>
            
        </div>
    );
};

export default FaqPage;

