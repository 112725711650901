const Button = ({ label, href = '#_' }) => {
    return (
      <div className="flex items-center justify-center">
        <a href={href} className="relative inline-block px-4 py-2 font-medium group">
          <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-dark-gold group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
          <span className="absolute inset-0 w-full h-full bg-burgundy border-2 border-dark-gold group-hover:bg-dark-gold"></span>
          <span className="relative text-dark-gold group-hover:text-burgundy">
            {label}
          </span>
        </a>
      </div>
    );
  };
  
  export default Button;
  