const testimonialsData = [
  {
    id: 1,
    author: "Tom Powers",
    quote:
      "I have had the pleasure of knowing and working with Cristina for over 10 years. In that time she has been a mentor, friend and confidant. Cristina has an exceptionally broad and deep knowledge of criminal investigations and has been specifically sought for cases where a trial was imminent. She has vast experience with complex, high-stakes legal investigations where tact discretion, and tenacity are paramount. She is the first person I call when I have a case that requires a unique, out of the box [respective. Cristina is a highly valued team member and well respected in the investigation community. ",
    descriptor: "Legal Investigator",
  },
  {
    id: 2,
    author: "Derrick Taylor",
    quote: `This may sound overly dramatic but the reality is that I was sitting in prison for 30+ years for a crime I didn’t commit and absolutely NOBODY was even slightly interested. I was slated to grow old and die in some prison infirmary, period.
    
    I had exhausted my direct appeals and had become resigned to my fate.
    
    As a sort of last chance “Hail Mary” I filed a habeas petition and was appointed a great lawyer (Atty. James Mortimer) who had an incredible investigator; Cristina.
    
    Cristina got to work right away and within weeks had begun to uncover a trove of evidence, both inside and outside the United States, that ultimately led to the State of Connecticut moving the Court for my immediate release from prison, which was granted and I was released on 7/26/23. Up until that day my discharge date was in 2072.
    
    ANYONE who truly needs a fighter in their corner, someone who will go through a “Dead End” with a bulldozer, would be incredibly fortunate to have Cristina take his or her case.
    
    The Kenzie Group, with Cristina in the driver’s seat, will undoubtedly maintain the standards and professionalism that I credit with giving me my life back, I don’t think Cristina would have it any other way.`,
    descriptor: "Freed Man",
    link: "https://ctexaminer.com/2023/12/29/the-state-of-connecticut-knows-derrick-taylor-didnt-murder-anyone/",
  },
  // ... other testimonials
];

export default testimonialsData;
