import React from "react";
import logo from "../../assets/logo.png";
import Button from "../common/BasicButton";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import ndia from "../../assets/ndia-logo.jpg";
import oali from "../../assets/oali-logo.jpg";
import ocdla from "../../assets/ocdla-logo.png"

const ContactItem = ({ icon, details }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex-shrink-0">{icon}</div>
      <div className="text-sm">{details}</div>
    </div>
  );
};

export const Footer = () => {
  return (
    <div className="relative mt-16 bg-navy">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-dark-gold"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentColor"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        {/* Footer Body */}
        <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6 text-dark-gold">
          <div className="md:max-w-md lg:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img
                src={logo}
                alt="Company Logo"
                className="w-16"
                viewBox="0 0 24 24"
              />
              <span className="ml-2 text-xl font-bold tracking-wide text-gold uppercase">
                The Kenzie Group
              </span>
            </a>
            <div className="mt-4 lg:max-w-sm">
              <p className="text-sm">
                Our mission is to provide our clients with meticulous and
                trustworthy legal support services. We believe in due process,
                transparency, and the right to a robust defense. With every
                case, we strive to ensure that justice is not just a principle,
                but a reality for our clients.
              </p>
            </div>
          </div>
          {/* Footer Nav */}
          <div className="grid grid-cols- gap-3 row-gap-8 lg:col-span-4 md:grid-cols-4">
            <div>
              <p className="font-semibold tracking-wide">Contact</p>
              <ul className="mt-2 space-y-2 text-old-text">
                <ContactItem
                  icon={<PhoneIcon className="h-4 w-4" />}
                  details="(971) 357-5202"
                />
                <a href="mailto:office@thekenziegroup.com" class="hover:text-burgundy hover:underline">
                  <ContactItem
                    icon={<EnvelopeIcon className="h-4 w-4" />}
                    details="office@thekenziegroup.com"
                  />
                </a>
                <ContactItem
                  icon={<MapPinIcon className="h-4 w-4" />}
                  details="330 NE Lincoln St. Suite 100 Hillsboro, OR 97124"
                />
                <ContactItem
                  icon={<MapPinIcon className="h-4 w-4" />}
                  details="415 Boston Post Rd. Suite 3-1177 Milford, CT 06460"
                />

                <Button
                  label="Consultation Request"
                  href="/contact-us"
                />
              </ul>
            </div>
            <div className="pb-4 lg:pl-8">
              <p className="font-semibold tracking-wide">Navigation</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="/about-us"
                    className="transition-colors duration-300 text-gold hover:text-burgundy"
                  >
                    Our Team
                  </a>
                </li>
                <li>
                  <a
                    href="/client-testimonials"
                    className="transition-colors duration-300 text-gold hover:text-burgundy"
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <a
                    href="/private-investigation-services"
                    className="transition-colors duration-300 text-gold hover:text-burgundy"
                  >
                    Services
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-semibold tracking-wide">Legal</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="/terms-of-service"
                    className="transition-colors duration-300 text-gold hover:text-burgundy"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                    className="transition-colors duration-300 text-gold hover:text-burgundy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img src={oali} alt="Logo for the Oregon Association of Licensed Investigators" className="flex flex-1" link="www.oali.org" style={{ width: '100px', marginBottom: '8px' }} />
              <img src={ndia} alt="Logo for the National Defender Investigator Association" className="flex flex-1" style={{ width: '150px', marginBottom: '8px' }} />
              <img src={ocdla} alt="Logo for the Oregon Criminal Defense Lawers Association" className="flex flex-1 bg-white h-[120px] w-[80px]" />
            </div>
          </div>
        </div>

        {/* Copyright and Social Media Links */}
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-off-white sm:flex-row">
          {/* <p className="text-sm text-off-white">
            © Copyright 2024 The Kenzie Group. All rights reserved.
          </p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="/"
              className="transition-colors duration-300 text-dark-gold hover:text-gold"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5"
              >
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-dark-gold hover:text-gold"
            >
              <svg
                viewBox="0 0 30 30"
                fill="currentColor"
                className="h-6"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="4"
                />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-dark-gold hover:text-gold"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5"
              >
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
          </div>*/}
        </div> 
      </div>
    </div>
  );
};

export default Footer;
